<template>
<Navbar :user="user" />
    <section class="instructor-details-area pt-100 pb-70" v-if="!isLoading">
        <div class="container">
            <div class="row">
                <div class="col-md-2" id="sticky-sidebar">
                    <div class="sticky-top">
                        <Sidebar :user="user" />
                    </div>
                </div>
                <div class="col" id="main" >
                   <div v-if="user.status == 3">
                        <div class="pb-5" >
                            <h3 class="text-center">{{ classroom.name }} Trainees</h3>
                            <div class="d-flex flex-row-reverse">
                                <router-link :to="{ name: 'TrainerClassRoomDashboard'}" class="btn default-btn end-0">
                                    To ClassRoom
                                </router-link>
                            </div>
                            <div class="alert alert-success" v-if="success">
                                {{ success }}
                            </div>
                            <div class="alert alert-danger" v-if="errors[0]">
                                {{ errors }}
                            </div>
                            <div class="form-75 dropdown-search">
                                <input class="form-control me-2  gx-5 dropdown-input" type="search" placeholder="Type search trainees by emails" aria-label="Search" 
                                v-model="search" @keydown.enter="isOpen = false" @keydown.esc="isOpen = false" @input="debouncedOnChange">
                                <div id="passwordHelpBlock" class="form-text">Type email to search trainee in our system, if email not found we will send an invitation via email to join the classroom</div>
                                <div class="dropdown-list">
                                <div class="btn text-center shadow-lg ms-2 me-auto" v-if="isSearching">Searching...</div>
                                <div class="row justify-content-center" v-else-if="searchedTrainees.length">
                                    <div class="col-md-6" v-for="trainee in searchedTrainees" :key="trainee.id">
                                        <button class="btn ms-2 me-auto shadow-lg" @click="addTraineeToClassRoom(trainee.id)">
                                            <div class="text-small">{{ trainee.fname }} {{ trainee.lname }}</div>
                                            <div style="text-size: 10px;"> <span class="text-default"> {{ trainee.email }}</span></div>
                                        </button>
                                    </div>
                                </div>
                                <div v-else-if="search">
                                    <button class="btn ms-2 me-auto shadow-lg" @click="inviteByEmail()">
                                        <div class="text-small">Trainee Not Found Click to Invite</div>
                                        <div style="text-size: 10px;"> <span class="text-default"> {{ search }}</span></div>
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center pt-5" v-if="classroom.users.length">
                            <div class="col-md-2" v-for="trainee in classroom.users" :key="trainee.id">
                                <div class="single-instructor-member mb-30">
                                    <div class="member-image">
                                        <img src="@/assets/img/team/1.jpg" alt="images">
                                    </div>
                                    <div class="member-content">
                                        <h6><a href="#">{{ trainee.fname }} {{ trainee.lname }}</a></h6>
                                        <ul class="social">
                                            <li>
                                                <a @click="removeFromClassroom(trainee.id)" class="btn instagram">
                                                    <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pt-5" v-else>
                            <h3 class="text-center">No Trainee in This Classroom</h3>
                        </div>
                   </div>
                    <div class="pt-70" v-else>
                        <h6 class="alert alert-danger text-center">You Donot Have Permission to Access this Page</h6>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/trainer/Sidebar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import _debounce from 'lodash.debounce';
export default {
    components: { Navbar,Loading, Sidebar, },
    data(){
        return {
            isLoading: true,
            token: localStorage.getItem('user_data'),
            errors: {},
            user: {},
            classroom: {},
            searchedTrainees:{},
            search:'',
            success:false,
            isOpen: false,
            isSearching: false,
            form:{
                classroomId: this.$route.params.id
            }
        }
    },
    computed: {
        debouncedOnChange () {
            if(this.search) {
                return _debounce(this.findTraineesByEmail, 700);
            }
            return _debounce(this.nullFunction)
        },
    },
    methods:{
        inviteByEmail(){
            axios.post('https://apitraining.vipawaworks.com/api/trainee/send_invitation_link/' + this.search, this.form).then(response => {
                this.success = response.data.success
                this.errors = false
            }).catch(errors => {
               this.errors = errors.response.data.errors
               this.success = false
            }) 
        },
        addTraineeToClassRoom(id){
            axios.post('https://apitraining.vipawaworks.com/api/trainee/assign_to_classroom/' + id, this.form).then(response => {
                this.success = response.data.success
                this.errors = false
                this.getTrainees()
                 this.$forceUpdate()
            }).catch(errors => {
               this.errors = errors.response.data.errors
               this.success = false
            }) 
        },
        removeFromClassroom(id){
            axios.post('https://apitraining.vipawaworks.com/api/trainee/remove_from_classroom/' + id, this.form).then(response => {
                this.success = response.data.success
                this.errors = false
                this.getTrainees()
                 this.$forceUpdate()
            }).catch(errors => {
               this.errors = errors.response.data.errors
               this.success = false
            }) 
        },
        getTrainees(){
            let id = this.$route.params.id
           axios.get('https://apitraining.vipawaworks.com/api/classroom/profile_with_students/' + id).then(response => {
                this.classroom = response.data
            }).catch(errors => {
               this.errors = errors.response.data.errors
            }) 
        },
        findTraineesByEmail(){
            this.isSearching = true
            axios.get('https://apitraining.vipawaworks.com/api/trainee/search_trainee_by_email/' + this.search).then(response => {
                this.searchedTrainees = response.data
                this.isSearching = false
            }).catch(errors => {
               this.errors = errors.response.data.errors
               this.isSearching = false
            }) 
        },
        nullFunction(){
            this.searchedTrainees = []
        },
        getUser(){
            axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
                this.user = response.data
            }).catch(errors => {
                if (errors.response.status === 401) {
                localStorage.removeItem('user_data')
                this.$router.push({ name: 'Login'})
                }
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
    },
    created(){
        document.title = `Class Room - Training`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getUser() 
        this.getTrainees()
    }
}
</script>

<style>

</style>